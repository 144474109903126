.fake-apple-pay-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: black; /* Use Apple Pay's blue color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid black; /* Add black border */
  width: 100%;
  min-height: 62px;
}



.apple-pay-logo {
  margin: auto;
  color: white;
  font-size: 1.3em;
  font-weight: bold;

}


.button-label {
  font-weight: bold;
  font-size: 16px;
}

.credit-card-icon {
  /* Set the font size to adjust the icon size */
  font-size: 1.3em;
}