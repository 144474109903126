/* ============================================================== 
     # Donation 
=================================================================== */


.donation-area {
  position: relative;
}

#bg-wrap.fixed-bg.spring {
  top: -50px;
  height: 105vh; /* Set height to 65% of the viewport height */
}

#bg-wrap.fixed-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 65vh; /* Set height to 65% of the viewport height */
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  transform-origin: 0 0;
  transform: skewY(-12deg);
  z-index: -1; /* To ensure it stays behind other content */
  overflow: hidden; /* Add this line to hide overflow */
}

.placeholder .fixed-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  transform: none;
}

label.coverFeeOption {
  font-size: 1em;
  font-weight: normal !important;
}

.donation-area .fixed-bg::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #000000 none repeat scroll 0 0;
  z-index: -2;
  opacity: 0.2;
}



  .donation-area .fixed-bg.monthly {
    background-position: center center;
    background-image: url("../img/monthly_mobile.png");
    background-size: cover;
    background-attachment: fixed;
  }

  @media screen and (max-width: 1400px) {

  
    .donation-area .fixed-bg.monthly {
      background-position: center top; /* Adjust position for smaller screens */
      background-image: url("../img/monthly_mobile.png");
    }
  }
  
  .donation-area .fixed-bg::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;

    z-index: -2;
    opacity: 0.2;
  }
  
  @media only screen and (min-width: 1400px) {
    .donation-area .fixed-bg {
      left: 0;
      right: 0;
    }
  }
  
  .donation-area .info > h5 {
    color: #2dccd3;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  
  .hibiscus .donation-area .info > h5 {
      color: #bf3654;
  }
  
  .info h2 {
    font-weight: 700;
    margin-bottom: 25px;
  }
  
  .info .question {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  
  .info .question .icon i {
    display: inline-block;
    background: #2dccd3;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    margin-right: 35px;
    position: relative;
    z-index: 1;
    margin-left: 15px;
  }
  
  .hibiscus .donation-area .info .question .icon i {
      background: #bf3654;
      color: #ffffff;
  }
  
  .donation-area .info .question .icon i::after {
    position: absolute;
    left: -15px;
    top: -15px;
    content: "";
    height: 80px;
    width: 80px;
    background: #2dccd3;
    z-index: -1;
    border-radius: 50%;
    opacity: 0.4;
  }
  
  .hibiscus .donation-area .info .question .icon i::after {
      background: #bf3654;
  }
  
  .donation-area .info .question .info h5 {
    text-transform: inherit;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .donation-area.bg-less .info .question .info h5 {
    color: #232323;
  }
  
  .donation-area .info .question .info span {
    font-weight: 600;
  }
  
  .donation-area .donation-form {
    padding-left: 35px;
  }
  
  .donation-area form {
    padding: 50px;
    position: relative;
    z-index: 1;
  }
  
  .donation-area.bg-gray form {
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Adjust the values here */
    border-radius: 32px;
  }
  
  
  .donation-area form::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #ffffff;
    z-index: -1;
    opacity: 0.7;
    border-radius: 32px;
  }

  .donation-area form.spring-info::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #2dccd3;
    z-index: -1;
    opacity: 0.7;
    border-radius: 32px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto; 
    background-image: url("../img/spring-lockup.png");
  }
  
  .donation-area form label {
    color: #232323;
    font-weight: 600;
  }
  
  .donation-area form input,
  .donation-area form .nice-select {
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.7);
    color: #232323;
  }
  
  .donation-area.bg-gray form input,
  .donation-area.bg-gray form .nice-select {
    border: 1px solid #e7e7e7;
    color: #232323;
  }
  
  .donation-form .nice-select span.current {
    position: relative;
    top: -6px;
    font-size: 16px;
  }
  
  .donation-area .donation-form span.input-group-addon {
    background-color: #2dccd3;
    padding: 6px 12px;
    line-height: 38px;
    border-radius: 0 5px 5px 0;
  }
  
  .donation-area.bg-gray .donation-form span.input-group-addon {
      background-color: #e7eef7;
      color: #232323;
  }
  
  .hibiscus .banner-area .content span {
      background-color: #bf3654;
  }
  
  .donation-area .donation-form input::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
    color: #232323;
  }
  
  .donation-area .donation-form input::-moz-placeholder {
   /* Firefox 19+ */
    color: #232323;
  }
  
  .donation-area .donation-form input:-ms-input-placeholder {
   /* IE 10+ */
    color: #232323;
  }
  
  .donation-area .donation-form input:-moz-placeholder {
   /* Firefox 18- */
    color: #232323;
  }
  
  .donation-area .donation-form button {
    border-radius: 5px;
    margin-top: 15px;
  }
  
  #donateType input {
    min-height: 0px;
  }

  #billing-country input {
    min-height: 0px;
  }

  #billing-province input {
    min-height: 0px;
  }

  #country input {
    min-height: 0px;
  }

  #province input {
    min-height: 0px;
  }

  #monthly input {
    min-height: 0px;
  }

  #tribute input {
    min-height: 0px;
  }

  #notify input {
    min-height: 0px;
  }


  #payFees input {
    min-height: 0px;
  }

  #notifyContactOptions input {
    min-height: 0px;
  }

  #anonymous input {
    min-height: 0px;
  }

  #ecard input {
    min-height: 0px;
  }

  #behalf input {
    min-height: 0px;
  }

  #wineWall input {
    min-height: 0px;
  }

  #organization input {
    min-height: 0px;
  }

  #billingAddress input {
    min-height: 0px;
  }

  #wording input {
    min-height: 0px;
  }
  
  .StripeElement {
    min-height:50px;
    padding-top: 13px;

  }


  .donation-area .donation-form  button.donation-amount-options {
    border-radius: 40px;
    margin-top: 0px;
  }

  @media (max-width: 991px) { 
    .donation-area .donation-form  button.donation-amount-options {
      margin-top: 15px;
    }
  }


  .donation-area.bg-gray form.book-form-element {
    /* Fallback for older browsers */
    background: rgb(2,0,36);
    
    /* Gradient background */
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(45,204,211,1) 35%, rgba(0,212,255,1) 100%);
}

p.spring-lockup {
  margin-right: -50px;
  margin-left: -50px;
}

.names-container {
  margin-top: 50px;
  position: relative;
  text-align: center;

}

@media screen and (max-width: 767px) {
  p.spring-lockup {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.names-container p {
  color: white;
  font-size: 5em;
}

@media screen and (max-width: 767px) {
  .names-container p {
    font-size: 4em;
  }
}
