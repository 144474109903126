
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');

@font-face {
  font-family: 'Inter';
  src: url('./assets/font/SF-Pro-Text-Regular.otf') format('opentype');
  /* Add other font properties like font-weight and font-style if needed */
}

@font-face {
  font-family: 'Roboto Bold'; /* Specify the font-family name */
  src: url('./assets/font/SF-Pro-Display-Bold.otf') format('opentype'); /* Set the correct path to the font file */
  font-weight: bold; /* Define the font-weight */
  /* Other font properties if needed */
}

@font-face {
  font-family: 'Roboto'; /* Specify the font-family name */
  src: url('./assets/font/SF-Pro-Text-Regular.otf') format('opentype'); /* Set the correct path to the font file */
}

@font-face {
  font-family: 'Roboto Black'; /* Specify the font-family name */
  src: url('./assets/font/SF-Pro-Display-Black.otf') format('opentype'); /* Set the correct path to the font file */
}




/*
** General Styles for HTML tags
*/

* {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

body  {
  font-family: 'Inter', sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  overflow-x: hidden;
  background-color: #ffffff;
  color: #666666;
}

html, body, .wrapper {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: #232323;
  font-weight: normal;
  line-height: 1.2;
  font-family: 'Roboto Bold', sans-serif;
  letter-spacing: 0;
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 15px;
}

img {
  border: none;
  outline: none;
  max-width: 100%;
}

label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%;
}

a, .btn, button {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  outline: medium none;
}

a img, iframe {
  border: none;
}

p {
  color: #666666;
  margin: 0 0 15px;
  text-transform: none;
  font-weight: 400;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eee;
}

pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  word-wrap: break-word;
  word-break: break-all;
  color: #333;
  border: 1px solid #ededed;
  border-radius: inherit;
  background-color: #f9f9f9;
  font-size: 13px;
  line-height: 1.42857143;
}

input:focus, textarea:focus, 
 select:focus {
  outline: none;
  box-shadow: inherit;
}

ul {
  margin: 0;
  list-style-type: none;
}

a, a:active, a:focus, a:hover {
  outline: none;
  text-decoration: none;
  color: #232323;
}

a:hover {
  color: #232323;
}

a {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

b, strong {
  font-weight: 900;
}

.btn.active, .btn:active {
  background-image: inherit !important;
}

.btn.focus, 
 .btn:active:focus, 
 .btn:active:hover, 
 .btn:focus, 
 .btn:hover {
  outline: 0;
}

.btn.circle {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.btn.active, .btn:active {
  outline: 0;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

input {
  border: 1px solid #e7e7e7;
  border-radius: inherit;
  box-shadow: inherit;
  min-height: 50px;
}

/* Default CSS */
.container-medium {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

.container-full {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

@media (min-width:576px) {
  .container-medium {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .container-medium {
    max-width: 720px;
  }
}

@media (min-width:992px) {
  .container-medium {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container-medium {
    max-width: 1400px;
    width: 80%;
  }
}

@media (min-width:576px) {
  .container-full {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .container-full {
    max-width: 720px;
  }
}

@media (min-width:992px) {
  .container-full {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container-full {
    max-width: 1400px;
    width: 90%;
  }
}

.fixed-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg-cover {
  background-position: center center;
  background-size: cover;
}

.bg-cover-bottom {
  background-size: cover !important;
  background-position: left bottom !important;
  background-repeat: no-repeat;
}

.bg-bottom-center {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.bg-contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-fixed {
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}



.bg-gray-hard {
  background-color: #d1eeec;
}

.bg-theme-small {
  background-color: #edf5ff;
}

.bg-light {
  background-color: #ffffff;
}

.bg-theme {
  background-color: #2dccd3;
}

.hibiscus .bg-theme {
  background-color: #bf3654;
}

.bg-light-gradient {
  background: linear-gradient(90deg, rgba(244,247,252,1) 36%, rgba(255,255,255,1) 100%);
}

.gradient-bg {
  background-image: linear-gradient(90deg, rgba(2,59,166,1) 0%, rgba(67,126,235,1) 100%);
  background-color: #2dccd3;
}

.container-md {
  width: 90%;
  margin: auto;
  position: relative;
}

.text-italic {
  font-style: italic;
}

.text-light h1, 
 .text-light h2, 
 .text-light h3, 
 .text-light h4, 
 .text-light h5, 
 .text-light h6, 
 .text-light p, 
 .text-light a {
  color: #ffffff;
}

.shadow {
  position: relative;
  z-index: 1;
}

.shadow.dark::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.dark-hard::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.light::after {
  background: #ffffff none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.theme::after {
  background: #2dccd3 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.hibiscus .shadow.theme::after {
    background: #bf3654;
}

.shadow.theme-hard::after {
  background: #2dccd3 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.default-padding,
.default-padding-top,
.default-padding-bottom,
.default-padding-mx {
  position: relative;
  z-index: 1;
}

.default-padding {
  padding-bottom: 120px;
  padding-top: 120px;
}

.default-padding-top {
  padding-top: 120px;
}

.default-padding-bottom {
  padding-bottom: 120px;
}

.default-padding.bottom-less {
  padding-top: 120px;
  padding-bottom: 90px;
}

.default-padding-bottom.bottom-less {
  margin-bottom: -30px;
}

.default-padding-top.bottom-less {
  margin-bottom: -30px;
}

.top-border {
  border-top: 1px solid #e7e7e7;
}

.align-center {
  align-items: center;
}

.btn {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 25px;
  text-transform: capitalize;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 5px;
  font-size: 17px;
  border-radius: inherit;
}

.form-btn {
  font-family: 'Roboto', sans-serif;
}

.btn.btn-icon i {
  font-size: 50px;
  align-items: center;
  float: left;
  margin-right: 10px;
}

.btn.btn-icon {
  padding: 0;
  line-height: 50px;
}

.btn.btn-icon:hover {
  color: #ffffff;
}

.btn-md {
  padding: 15px 40px;
}

.btn-sm {
  padding: 10px 35px;
  font-size: 14px;
}

.btn.btn-sm-pro {
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
  padding: 4px 35px;
  display: inline-block;
}

.btn-border-light {
  border: 2px solid #ffffff;
}

.btn.btn-sm-pro.btn-border-light:hover, 
 .btn.btn-sm-pro.btn-border-light:focus {
  background-color: #ffffff;
  color: #232323;
  border: 2px solid #ffffff;
}

.btn-dark {
  background-color: #232323;
  color: #ffffff;
  border: 2px solid #232323;
}

.btn-dark.border {
  background-color: transparent;
  color: #232323;
  border: 2px solid #232323 !important;
}

.btn-dark.border:hover {
  background-color: #232323;
  color: #ffffff !important;
  border: 2px solid #232323 !important;
}

.btn-gray.border {
  background-color: transparent;
  color: #2dccd3;
  border: 2px solid #e7e7e7 !important;
}

.hibiscus .btn-gray.border {
  color: #bf3654;
}

.btn-gray.border:hover {
  background-color: #2dccd3;
  color: #ffffff !important;
  border: 2px solid #2dccd3 !important;
}

.hibiscus .btn-gray.border:hover {
  background-color: #bf3654;
  color: #ffffff !important;
  border: 2px solid #bf3654 !important;
}

.btn-gray.effect {
  background: #fafafa;
  border: 1px solid #e7e7e7 !important;
}

.btn.btn-light {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid #ffffff;
  color: #232323;
}

.btn.btn-light.border {
  background: transparent;
  border: 2px solid #ffffff !important;
  color: #ffffff;
}

.btn.btn-light.border:hover {
  background: #ffffff none repeat scroll 0 0 !important;
  border: 2px solid #ffffff !important;
  color: #232323 !important;
}

.btn-standard {
  display: inline-block;
  text-align: center;
  color: #2dccd3;
  position: relative;
  margin-top: 15px;
  border: 1px solid #e7e7e7;
  padding: 10px 30px;
  text-transform: capitalize;
  border-radius: 30px;
  font-weight: 800;
}

.btn-standard.md {
  padding: 13px 45px;
}

.btn-standard i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  color: #2dccd3;
  font-size: 15px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 10px #96aac1;
  z-index: 1;
  margin-right: 5px;
  background: #ffffff;
}

.bg-theme .btn-standard:hover,
.bg-dark .btn-standard:hover,
.bg-gradient .btn-standard:hover,
.shadow .btn-standard:hover {
  background: #ffffff;
  color: #2dccd3;
}

.hibiscus .bg-theme .btn-standard:hover,
.hibiscus .bg-dark .btn-standard:hover,
.hibiscus .bg-gradient .btn-standard:hover,
.hibiscus .shadow .btn-standard:hover {
  background: #ffffff;
  color: #bf3654;
}


.btn-dark:hover, 
 .btn-dark:focus {
  color: #232323 !important;
  background: transparent;
}

.bg-dark {
  background: #001d4c !important;
}

.bg-dark-hard {
  background: #08111e;
}

.bg-gradient {
  background: #2dccd3;
  background: -webkit-linear-gradient(top left, #2dccd3, #061169);
  background: -moz-linear-gradient(top left, #2dccd3, #061169);
  background: linear-gradient(to bottom right, #2dccd3, #061169);
}

.btn-theme {
  background-color: #2dccd3;
  color: #ffffff !important;
  border: 2px solid #2dccd3;
}

.hibiscus .btn-theme {
  background-color: #bf3654;
  color: #ffffff !important;
  border: 2px solid #bf3654;
}

.btn-theme.border {
  background-color: transparent;
  color: #232323 !important;
  border: 2px solid #2dccd3 !important;
}

.hibiscus .btn-theme.border {
  background-color: transparent;
  color: #232323 !important;
  border: 2px solid #bf3654 !important;
}

.text-light .btn-theme.border {
  color: #ffffff !important;
}

.btn-theme.border:hover {
  background-color: #2dccd3;
  color: #ffffff !important;
  border: 2px solid #2dccd3;
}

.hibiscus .btn-theme.border:hover {
  background-color: #bf3654;
  color: #ffffff !important;
  border: 2px solid #bf3654;
}

.btn-theme.effect:hover, 
.btn-theme.effect:focus {
  background: #2dccd3 none repeat scroll 0 0;
  border: 2px solid #2dccd3;
  color: #ffffff;
}

.hibiscus .btn-theme.effect:hover, 
.hibiscus .btn-theme.effect:focus {
  background: #bf3654 none repeat scroll 0 0;
  border: 2px solid #bf3654;
  color: #ffffff;
}


.btn.btn-sm-lm {
  font-size: 12px;
  padding: 4px 35px;
}

.bg-theme a.btn-theme.btn:hover,
.bg-theme a.btn-theme.btn:focus {
  border: 2px solid #ffffff;
  color: #ffffff;
}

/* Gradient Button */

.btn-gradient,
.btn-gradient:focus {
  position: relative;
  z-index: 1;
  color: #ffffff !important;
}

.btn-gradient:hover {
  color: #ffffff;
}

.btn-gradient::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(59,207,147,1) 0%, rgba(15,148,94,1) 100%);
  transition: all 0.35s ease-in-out;
  z-index: -1;
  box-shadow: 0 0 10px rgba(255, 136, 0, 0.5), inset 0 0 1px 1px #2dccd3;
}

.hibiscus .btn-gradient::after {
  background: linear-gradient(90deg, rgba(191,54,84,1) 0%, rgba(124,6,32,1) 100%);
  box-shadow: 0 0 10px rgba(255, 136, 0, 0.5), inset 0 0 1px 1px #bf3654;
}

.btn i {
  font-size: 12px;
}

.btn-gradient i {
  display: inline-block;
  line-height: 28px;
  color: #ffffff;
  font-size: 14px;
  margin-left: 5px;
}

.btn-gradient.icon-normal i {
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: inherit;
  position: relative;
  top: 2px;
  margin-right: 0;
  height: auto;
  width: auto;
}

.btn-gradient.icon-left i {
  margin-left: 0;
  margin-right: 5px;
}

.inc-icon i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 28px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 10px;
  border-radius: 50%;
  margin-left: 5px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
}

.btn-gradient.btn-sm {
  padding: 10px 30px;
}

.btn.btn-transparent.border {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.btn.btn-transparent.border:hover {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.07);
  color: #ffffff !important;
}

.relative {
  position: relative;
}

.bg-fixed {
  background-attachment: fixed !important;
  background-position: center center !important;
  background-size: cover !important;
}

.bg-cover {
  background-position: center center !important;
  background-size: cover !important;
}

.overflow-hidden {
  overflow: hidden;
}

button, button:focus {
  border: none !important;
  box-shadow: inherit !important;
  outline: inherit !important;
}

header {
  position: relative;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a {
  position: relative;
  z-index: 1;
  margin-left: 3px;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a {
  border: medium none;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a::after {
  background: #ffffff none repeat scroll 0 0;
  bottom: -2px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 0;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a.active::after,
header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a:hover::after {
  width: 100%;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a.active::after,
header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a:hover::after {
  width: 0;
}

.attr-nav .social.right li {
  margin-left: 20px;
}

.attr-nav .social li a {
  font-size: 16px;
}

/* Nice Select CSS */
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 10px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 15px;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, 
.nice-select.open, 
.nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -o-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: block;
  margin: 0;
}

.nice-select .option:hover, 
.nice-select .option.focus, 
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}


/* ============================================================== 
     # Custom
=================================================================== */

.form-group {
  margin-bottom: 1rem;
}

.loading-text {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 60px;
  position: fixed;
  margin-top: 250px;
  font-size: 30px;
  color: #54575a;
  z-index: 99999999; /* Ensure it's above other content */
}

.loading-text::after {
  content: ".";
  animation: ellipsis 1.25s infinite steps(3);
}

@keyframes ellipsis {
  0% { content: "."; }
  20% { content: ".."; }
  40% { content: "..."; }
  60% { content: "...."; }
  80% { content: "....."; }
  100% { content: "......"; }
}


.logo-container {
  position: absolute;
}

.mobile-logo {
  max-width: 200px; /* Adjust the size as needed */
  height: auto; /* Maintain the aspect ratio */
}

.mobile-logo.lockup-logo {
  max-width: 300px; /* Adjust the size as needed */
  height: auto; /* Maintain the aspect ratio */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999; /* Ensure it's above other content */
}

.spinner {
  /* Your spinner styling here */
  /* Example: */
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #2dccd3;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spin 1s linear infinite;
  z-index: 9999999; /* Ensure it's above other content */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.required {
	color: red;
}