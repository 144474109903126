.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 20px;
    width: 100%;
    transition: transform 0.5s ease-in-out; /* Transition for smooth sliding with ease-in-out effect */
    z-index: 1000;
  }
  
  .navbar.scrolled {
    transform: translateY(-150%); /* Slide the blue section up */
    background: transparent; /* Change to your desired color */
  }
  
  .logo {
    /* Adjust width and height as needed */
    width: 220px;
    height: 100px; /* Automatically adjust height to maintain aspect ratio */
  }

  .logo.lockup-logo {
    /* Adjust width and height as needed */
    width: 440px;
    height: 200px; /* Automatically adjust height to maintain aspect ratio */
  }
  
  @media (max-width: 991px) {
    .logo {
      height: 50px; /* Automatically adjust height to maintain aspect ratio */
    }

    .logo.lockup-logo {
      height: 100px; /* Automatically adjust height to maintain aspect ratio */
    }
  }

  .logo img {
    max-width: 100%;
    height: auto;
  }
  
  
  .nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-right: 20px;
  }
  
  .nav-links li {
    margin-left: 20px;
  }

  /* Styles for desktop */
.desktop-logo {
  display: block;
}

img.mobile-logo {
  display: none;
}

.loading-overlay img.mobile-logo {
  display: block;
  height: 150px;
  width: auto;
  position: absolute;
  top: -75px;
  left:-50px;
  padding: 10px;
  filter: brightness(200%); /* Adjust the percentage as needed */
}
  
  /* Media query for mobile view */
  @media (max-width: 991px) {
    .desktop-logo {
      display: none;
    }
    
    .mobile-logo {
      display: block;
    }

    .loading-overlay img.mobile-logo {
      display: block;
      height: 80px;
      width: auto;
      position: absolute;
      top: -40px;
      left:-30px;
      padding: 10px;
      filter: brightness(200%); /* Adjust the percentage as needed */
    }

     img.mobile-logo {
      display: block;
      height: 80px;
      width: auto;
      position: absolute;
      top: 30px;
      left:20px;
      padding: 10px;
      filter: brightness(200%); /* Adjust the percentage as needed */
    }

    img.mobile-logo.lockup-logo {
      display: block;
      height: 100px;
      width: auto;
      position: absolute;
      top: 30px;
      left: 50%; /* horizontally center */
      transform: translateX(-50%); /* move back half of its width */
      filter: brightness(100%); /* Adjust the percentage as needed */
    }
    
  }

  @media (max-width: 991px) {

    .navbar {
     top: 40px;
   }
 }
    
  /* Media query for mobile view */
  @media (max-width: 1200px) {

     .navbar {
      top: 40px;
    }

    .navbar.direct-mail {
      top: 20px;
    }
  }
 



  
  